<template>
  <div id="commodity">

    <el-button type="text" @click="dialogFormVisible = true">新增商品</el-button>

    <el-dialog title="新增卡密" :visible.sync="dialogFormVisible">
      <el-form :model="commodity" ref="card" :rules="rules">
        <el-form-item label="商品名称" :label-width="formLabelWidth" prop="names">
          <el-input v-model.number="commodity.names" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="价值" :label-width="formLabelWidth" prop="price">
          <el-input v-model.number="commodity.price" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="newComm('card')">确 定</el-button>
      </div>
    </el-dialog>

    <el-table
        ref="multipleTable"
        :data="pageData.dataList"
        style="width: 100%">
      <el-table-column
          width="280"
          label="商品名称">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.names }}</span>
        </template>
      </el-table-column>

      <el-table-column
          width="280"
          label="商品价值">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--              size="mini"-->
          <!--              @click="handleEdit(scope.$index, scope.row)">编辑-->
          <!--          </el-button>-->
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :total="pageData.total"
        :page-size="pageData.rows"
        :current-page="pageData.current"
        @current-change="loadAllFunctions">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "commodity",
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('价格不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value < 0) {
            callback(new Error('必须大于0'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var checkName = (rule, value, callback) => {
      if (value.length===0) {
        return callback(new Error('名称不能为空'));
      }
      callback();
    };
    return {
      pageData: {current: 1, rows: 10},
      commodity: {id: 0, names: '', price: 0},
      dialogFormVisible: false,
      formLabelWidth: '120px',
      rules: {
        names: [
          // { required: true, message: '请输入商品名称', trigger: 'blur' },
          {validator: checkName, trigger: 'blur'}
        ],
        price: [
          {validator: checkAge, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    pageFindCommodity() {
      this.axios.post('/commodity/page/find', this.pageData).then(res => {
        this.pageData = res.data;
        console.log(res.data)
      })
    },
    handleDelete(index, row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get("/commodity/delete/" + row.id).then(res => {
          if (res.data == "ok") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageFindCommodity();
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    newComm() {
      this.axios.post('/commodity/add', this.commodity).then(res => {
        if (res.data == 'ok') {
          this.$message.success('添加成功');
          this.dialogFormVisible = false;
          this.pageFindCommodity();
          return;
        }
        this.$message.error('添加失败');
      })
    },
    loadAllFunctions(a) {
      this.pageData.current = a;
      this.pageFindCommodity();
    }
  },
  mounted() {
    this.pageFindCommodity();
  }
}
</script>

<style scoped>

</style>