<template>
  <div>
    <el-container>
      <el-header class="h_r">
        <div class="title" @click="openPage3">
          <span class="my-logo">论文检查系统</span>
        </div>
        <el-menu default-active="2" class="el-menu-vertical-demo" mode="horizontal">
          <div id="kami" style="display: none">
            <el-submenu index="1">
              <span slot="title">home</span>
              <el-menu-item index="2">
                <span slot="title" v-on:click="handleOpen">home1</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span slot="title" v-on:click="openPage2">home2</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span slot="title" v-on:click="openPage4">home2</span>
              </el-menu-item>
            </el-submenu>
          </div>
          <el-menu-item index="2">
            <i class="el-icon-upload2"></i>
            <span slot="title" v-on:click="openPage1">论文上传</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-download"></i>
            <span slot="title" v-on:click="openPage3">报告下载</span>
          </el-menu-item>
        </el-menu>
      </el-header>
      <div class="jumbotron"></div>
      <div class="tops">
        <div class="top-content">

        </div>
        <p class="titleC">选择维普查重系统版本</p>
        <ul class="display">
          <li @click="goToPage('1')">
            <span class="icon">官方监测</span>
            <img src="@/assets/dis.png" alt="">
            <p class="dtitle">维普大学生版</p>
            <div class="price"><span class="num">4.00</span>元/1000字符 </div>
            <p class="detail">维普查重本科版又叫大学生版，结果与学校一致，多版本检测报告呈现多维度检测指标，如学校用的是维普系统，那么按学位选择查重系统即可，专科和本科选本科版，硕博选硕博</p>
            <div class="examine">立即检查</div>
          </li>
          <li @click="goToPage('2')">
            <span class="icon">官方监测</span>
            <img src="@/assets/dis.png" alt="">
            <p class="dtitle">维普研究生版</p>
            <div class="price"><span class="num">4.00</span>元/1000字符 </div>
            <p class="detail">维普研究生版适用于硕士论文、博士论文、研究生论文、MBA论文查重，对比库含硕博学位论文，数据库覆盖全面，检测结果与学校一致，硕博论文定稿检测使用。</p>
            <div class="examine">立即检查</div>
          </li>
          <li @click="goToPage('shangpin03')">
            <span class="icon">官方监测</span>
            <img src="@/assets/dis.png" alt="">
            <p class="dtitle">维普编辑部版</p>
            <div class="price"><span class="num">4.00</span>元/1000字符 </div>
            <p class="detail">维普编辑部版适用于编辑部等部门一般文章的检测，面向投稿期刊发表的用户，符合维普收录所有期刊的投稿审查标准，后期要发表的用户请选择维普职称版检测。</p>
            <div class="examine">立即检查</div>
          </li>
          <li @click="goToPage('shangpin04')">
            <span class="icon">官方监测</span>
            <img src="@/assets/dis.png" alt="">
            <p class="dtitle">维普职称版</p>
            <div class="price"><span class="num">4.00</span>元/1000字符 </div>
            <p class="detail">维普职称版检测系统可检测已发表或者即将要发表的论文，面向申报职称的各类专业技术人员、学生、教师、科研人员等，各省职称评审办公室均认可该检测结果。</p>
            <div class="examine">立即检查</div>
          </li>
        </ul>
      </div>
      <div class="footer">
        <div class="info">
          <a href="/upload"> 首页 </a>
          <a href="#/report"> 维普报告下载 </a>
          <a href="#"> 真伪验证 </a>
          <a href="#/weipu?id=2"> 维普查重入口 </a>
        </div>
        <p>Copyright © 2023 版权所有</p>
      </div>
    </el-container>

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods:{
    goToPage(param){
      this.$router.push('/upload?id=' + param)
    }
  }
}
</script>


<style scoped>
/* .el-col {
    overflow: hidden;
} */

.el-container {
  height: 100%;
}

/* .el-row {
    width: 100%;
} */

#test a {
  text-decoration: none;
  color: white;
}

.h_r {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 16%;
}

.title {
  display: flex;
  align-content: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 60px;
  padding-left: 50px;
  background: url("../../assets/logo.png") no-repeat scroll 0 0 transparent;
  width: 200px;
  height: 45px;
  background-size: 100%;
  margin: 10px 0 10px 5px;
  text-indent: -9999px;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border: 0;
}

.content {
  padding-left: 16%;
  padding-right: 16%;
}

.jumbotron {
  color: inherit;
  background-color: #0142a8;
}

.my-logo {
  width: 140px;
  height: 45px;
  display: block;
}


.footer {
  width: 100%;
  height: 160px;
  background: #2d2e32;
}

.footer .info {
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  margin-top: 20px;
}

.footer .info a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin: 0 18px;
}

.footer p {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #999;
}

.tops {
  width: 100%;
}

.tops .top-content {
  height: 480px;
  background-image: url('@/assets/top.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.tops .titleC {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.display {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.display li {
  position: relative;
  width: 250px;
  margin: 10px;
  height: 440px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  position: relative;
  padding: 15px;
}

.display li img {
  margin-top: 24px;
}

.dtitle {
  height: 40px;
  line-height: 40px;
  color: #4560c3;
  text-align: center;
  font-size: 20px;
  margin: 0;
  margin-top: 4px;
}

.price {
  padding: 15px 0;
  text-align: center;
  color: #34353c;
  text-decoration: none;
  font-size: 14px;
}

.num {
  font-size: 24px;
  color: #4560c3;
  font-weight: 600;
}

.detail {
  line-height: 24px;
  height: 150px;
  font-size: 13px;
  text-align: left;
  margin-bottom: 0;
}

.examine {
  padding: 0 25px;
  color: #fff;
  height: 40px;
  background-color: #4560c3;
  line-height: 40px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  width: 64px;
  margin: auto;
}

.display .icon {
  position: absolute;
  width: 32px;
  height: 32px;
  font-size: 12px;
  padding: 0;
  color: #fff;
  padding-left: 5px;
  padding-top: 4px;
  margin: 0;
  right: 10px;
  top: -5px;
  z-index: 999;
  background-image: url(@/assets/bac.png);
}
</style>