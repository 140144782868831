<template>
  <div id="login">
    <el-dialog
        style="width:800px;margin:0px auto;"
        title=""
        :visible.sync="dialogFormVisible">
      <!-- 登录表单-->
      <div id="loginForm">
        <el-form>
          <el-form-item>
            <h1 style="font-size:30px;color:#00B38A">登录</h1>
          </el-form-item>
          <el-form-item>
            <el-input v-model="user.account" placeholder="请输入账号..."></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="password" v-model="user.password" placeholder="请输入密码..."></el-input>
          </el-form-item>
        </el-form>
        <el-button
            style="width:100%;margin:0px auto;background-color: #00B38A;font-size:20px"
            type="primary"
            @click="login">确 定</el-button>
        <p></p>
        <!-- 微信登录图标 -->
        <!--        <img-->
        <!--            @click="goToLoginWX"-->
        <!--            src="http://www.lgstatic.com/lg-passport-fed/static/pc/modules/common/img/icon-wechat@2x_68c86d1.png"-->
        <!--            alt=""-->
        <!--        />-->
      </div>

      <!-- 二维码 -->
      <!--
      <wxlogin id="wxLoginForm" style="display:none"
              :appid="appid" :scope="scope" :redirect_uri="redirect_uri">
      </wxlogin>
      -->
      <div id="wxLoginForm"></div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "login",
  data(){
    return{
      dialogFormVisible:true,
      user:{account:'',password:''},
      responseData:''
    }
  },
  methods:{
    login(){
      if (this.user.account==''||this.user.password==''){
        return;
      }
      this.axios.post('/user/login',this.user).then(res=>{
        if (res.data==0){
          this.responseData=res.data;
          this.$message({message:'登录成功',type:"success"})
          sessionStorage.setItem('loginInfo',JSON.stringify(res.data));
          this.dialogFormVisible=false;

          this.$router.push({path:"/upload",query:{'success':this.responseData}});
        }else {
          this.$message({message:'登录失败',type:"warning"})
        }
      })
    },
    loginAndLogout(){
      this.dialogFormVisible=true;
    },
    judge(){
      if (this.responseData!=''){
        let kami = document.getElementById("kami");
        kami.style.display='block';
      }
    },
  }
}
</script>

<style scoped>

</style>