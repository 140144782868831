import Vue from 'vue'
import App from './App.vue'
import router from './router'
import qs from 'qs';
import downloadjs from "downloadjs";
import VueResource from 'vue-resource'
// import
//引入axios
import axios from 'axios'
Vue.prototype.axios=axios
import jquery from "jquery";
Vue.prototype.$ = jquery;
axios.defaults.withCredentials=true
//引入elementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css'

Vue.use(ElementUI)
Vue.use(VueResource)
Vue.prototype.axios=axios
Vue.prototype.$qs = qs;
//定义基础路径
axios.defaults.baseURL='/api'
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin)){  // 判断该路由是否需要登录权限
    if (sessionStorage.getItem('loginInfo')) {  // 判断当前用户的登录信息loginInfo是否存在
      next();
    } else {
      next({
        path: '/upload'
      })
    }
  }else {
    next();
  }
 
})
//携带cookie
Vue.prototype.axios.defaults.withCredentials=true
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
