<template>
  <div id="lunwenList">

    <el-dialog title="处理论文" customClass="customWidth" width="10%" height="80%" :visible.sync="dialogTo">
      <el-form :model="handleFile" ref="handleFile" :rules="rules">
        <el-form-item label="上传文件:" prop="handleFileName">
          <el-upload
              ref="upload"
              :multiple="false"
              class="upload-demo"
              action="/api/file/upload/single"
              :limit="1"
              :on-error="errorFile"
              :before-upload="checkFile"
              :on-remove="removeFile"
              :on-success="successSave">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;只能上传zip文件
            </div>
          </el-upload>
          <el-button style="margin-left: 10%" size="small" type="primary" @click="deleteFile">删除</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="downTo('handleFile')">确 定</el-button>
      </div>
    </el-dialog>

    <div class="block">
      <el-input v-model="searchKa" style="width: 200px" placeholder="请输入卡密"></el-input>
      <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="currentDate"
          type="datetime"
          placeholder="选择日期时间">
      </el-date-picker>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>

    <el-table
        ref="multipleTable"
        :data="pageHandleBO.dataList"
        style="width: 100%">

        <el-table-column
          width="100px"
          label="价值">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.card.value==null?'': scope.row.card.value}}</span>
        </template>
      </el-table-column>

      <el-table-column
          label="使用的卡密">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.handle.carMy }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column
          label="链接">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.papers.address }}</span>
        </template>
      </el-table-column> -->

      <el-table-column
          width="80px"
          label="作者">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.papers.author }}</span>
        </template>
      </el-table-column>

      <el-table-column
          width="100px"
          label="标题">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.papers.title }}</span>
        </template>
      </el-table-column>

      <el-table-column
          label="使用商品">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.cardGroupName }}</span>
        </template>
      </el-table-column>

      <el-table-column
          width="90px"
          label="字符数">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.papers.number }}</span>
        </template>
      </el-table-column>

      <el-table-column
          label="使用时间"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.handle.createDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
          width="100"
          label="状态">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.handle.status == 1 ? "处理完成" : "未处理" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="danger"
              @click="deleteRow(scope.$index, scope.row)">删除
          </el-button>
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">下载
          </el-button>
          <el-button
              size="mini"
              type="danger"
              @click="toDeal(scope.$index, scope.row)">{{ scope.row.handle.status == 1 ? '重新处理' : '去处理' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :total="pageHandleBO.total"
        :page-size="pageData.rows"
        :current-page="pageData.current"
        @current-change="loadAllFunctions">
    </el-pagination>
  </div>
</template>

<script>
import downloadjs from "downloadjs";

export default {
  name: "lunwenList",
  data() {
    return {
      searchKa:'',
      currentDate: '',
      pageData: {current: 1, rows: 10},
      handleFile: {handleFileName: '', carMy: ''},
      handle: {},
      pageHandleBO: [],
      dialogTo: false,
      formLabelWidth: '120px',
      rules: {
        handleFileName: [
          {required: true, message: '文件不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {

    pageFindHandle() {
      this.axios.post('/handle/page/find', this.pageData).then(res => {
        this.pageHandleBO = res.data;
        console.log(res)
      })
    },
    loadAllFunctions(a) {
      this.pageData.current = a;
      this.pageFindHandle();
    },
    toDeal(index, row) {
      this.handle = row.handle;
      this.dialogTo = true;
    },
    errorFile(err, file, fileList) {
      this.handleFile.handleFileName = '';
      this.$message({message: '上传失败,重新上传', type: 'error'})
    },
    removeFile(file, fileList) {
      if (file.response == '') {
        return;
      }
      let aaa = file.response;
      let ccc = aaa.substring(aaa.indexOf("2022-check-mulu") + "2022-check-mulu".length + 1, aaa.indexOf("?"));
      this.axios.get("/file/remove/" + ccc).then(res => {
        console.log(res)
        this.handleFile.handleFileName = '';
        this.$refs.upload.clearFiles();
        this.$message({message: "删除成功", type: 'warning'});
      })
    },
    checkFile(file) {
      // 上传前
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["zip"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({message: "上传文件只能是zip格式", type: 'warning'});
        return false;
      }
    },
    successSave(response, file, fileList) {
      this.handleFile.handleFileName = response;
    },
    search() {
      if (this.searchKa!=''&&this.currentDate!=''){
        this.pageData.condition=[{'kami':this.searchKa},{'date': this.currentDate}];
      }else if (this.searchKa != ''&&this.currentDate=='') {
        this.pageData.condition=[{'kami':this.searchKa}];
      }else if (this.searchKa == ''&&this.currentDate!=''){
        this.pageData.condition=[{'date': this.currentDate}];
      }else if (this.searchKa==''&& this.currentDate==''){
        this.pageData = {current: this.pageData.current, rows: this.pageData.rows}
      }
      this.pageFindHandle()
    },
    deleteFile() {
      let file = {response: this.handleFile.handleFileName}
      this.removeFile(file, null)
    },
    downTo(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.handleFile.carMy = this.handle.carMy;
          this.axios.post('/handlefile/add', this.handleFile).then(res => {
            if (res.data == 'ok') {
              this.$message({message: '处理成功', type: "success"});
              this.dialogTo = false;
              this.pageFindHandle();
              this.$refs.upload.clearFiles()
              return;
            }
            this.$message({message: '处理失败，重新处理', type: "error"})
            this.dialogTo = true;
          })
        } else {
          this.dialogTo = true;
          return false;
        }
      });
    },
    cancel() {
      let file = {response: this.handleFile.handleFileName}
      this.removeFile(file, null)
      this.dialogTo = false;
      this.$refs.upload.clearFiles()
    },
    handleEdit(index, row) {
      if (row.papers == null) {
        return;
      }
      var x = new XMLHttpRequest();
      x.open("GET", row.papers.address, true);
      x.responseType = 'blob';
      x.onload=function(e) {
        //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = url;
        a.download =row.papers.title+'_'+row.papers.author;
        a.click()
      }
      x.send();
      // this.axios.get(row.papers.address,{responseType:"blob"}).then(res=>{
      //   const blob=new Blob([res.data]);
      //   let link = document.createElement('a')
      //   link.style.display = 'none'
      //   link.href = URL.createObjectURL(blob);
      //   link.download=row.papers.title;
      //   link.click();
      // })

    },
    deleteRow(index, row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get("/handle/remove/" + row.handle.id).then(res => {
          if (res.data == "ok") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageFindHandle();
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
  mounted() {
    this.pageFindHandle();
  }
}
</script>

<style scoped>

.customwidth {
  width: 15% !important;
  min-width: 100px !important;

}

</style>