import Vue from 'vue'
import VueRouter from 'vue-router'
import test from "@/views/lunwen/test";
import kami from "@/views/lunwen/kami";
import upload from "@/views/lunwen/upload";
import weipu from "@/views/lunwen/weipu";
import lunwenDownload from "@/views/lunwen/lunwenDownload";
import lunwenList from "@/views/lunwen/lunwenList";
import report from "@/views/lunwen/report";
import login from "@/views/lunwen/login";
import commodity from "@/views/lunwen/commodity";
import home from "@/views/lunwen/home";
import gocheck from "@/views/lunwen/gocheck";
import gzd from "@/views/lunwen/gzd";

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}

const routes = [
  {
    path: '/',
    name: 'test',
    component: test,
    children:[
      {
        path: '/kami36FA64A76908B8489E7A89D5C72857C0',
        name: 'kami',
        component: kami,
        meta:{
          requireLogin:true // 当前路由需要校验，不需要就不用写
        }
      },
      {
        path:'/upload',
        name:'upload',
        component: upload,
      },
      {
        path:'/',
        name:'upload',
        component: upload,
      },
      {
        path:'/weipu',
        name:'weipu',
        component: weipu,
      },
      {
        path:'/gocheck',
        name:'gocheck',
        component: gocheck,
      },
      {
        path:'/gzd',
        name:'gzd',
        component: gocheck,
      },
      {
        path: '/lunwenDownload',
        name: 'lunwenDownload',
        component: lunwenDownload
      },
      {
        path: '/lunwenList2F00B141A80B3489DD3093464D9A424E',
        name: 'lunwenList',
        component: lunwenList,
        meta:{
          requireLogin:true // 当前路由需要校验，不需要就不用写
        }
      },
      {
        path: '/report',
        name: 'report',
        component: report
      },
      {
        path: '/commodityEC5CB3E6E19049C477878043D35194EE',
        name: 'commodity',
        component: commodity,
        meta:{
          requireLogin:true // 当前路由需要校验，不需要就不用写
        }
      },
     
    ]
  },
  {
    path: '/login1739991',
    name: '/login1739991',
    component: login
  }, {
    path: '/home',
    name: 'home',
    component: home
  },

]

const router = new VueRouter({


  routes

})

export default router
