<template>
  <div id="report">
    
    <div class="right-box">
      <p class="topTils"><i class="el-icon-search"></i>查询报告-- 出结果时间：1-30分钟，高峰期1-5小时\\\\可以隔几分钟点一下刷新进度按钮，刷新检测状态。</p>
      <div style="display: flex;width: 600px;">
        <p class="ttitle">检测卡号:</p>
        <div style="width: 290px;margin-right: 14px;">
          <el-input
            v-model.trim="carMy"
            placeholder="请输入或复制粘贴购买的检测卡号"
            style="display: inline-block"
        >
        </el-input>
        </div>
        <el-button
              type="danger"
              slot="suffix"
              @click="inquire"
          >
            点击查询
          </el-button
          >
      </div>
      <p class="topTils" style="margin-top: 50px;"><i class="el-icon-s-unfold"></i><span style="color:red;"> 注意：系统报告保留72小时，请及时下载报告到电脑保存</span></p>
      <div style="padding-top: 0;margin-top: -50px;" class="res-wrap">
        <table class="table">
          <thead>
          <tr>
            <th>论文标题</th>
            <th>论文作者</th>
            <th>检测时间</th> 
            <th>异常反馈</th>      
            <th>订单状态</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
                <span v-if="handleBo.papers">
                  {{ handleBo.papers.title }}
                </span>
            </td>
            <td>
                <span v-if="handleBo.papers">
                  {{ handleBo.papers.author }}
                </span>
            </td>
            <td>
                <span v-if="handleBo.handle">
                  {{ handleBo.handle.createDate }}
                </span>
            </td>
            <td>
                <span v-if="handleBo.papers">
                  {{ handleBo.papers.phone }}
                </span>
            </td>
            <td>
                <span v-if="handleBo.handle">
                  {{ handleBo.handle.status == 1 ? "已完成" : "检测中" }}
                </span>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="no-res ng-scope" v-if="!handleBo.papers">
          <img src="../../assets/no-report.png"/>
          <p>没有查询出报告!</p>
        </div>
        <p class="all-count">可以隔几分钟点一下刷新进度按钮，订单状态显示已完成后即可下载报告！关闭网页，电脑关机都不影响检测！</p>

        <el-form
            ref="form"
            :model="handleBo"
            label-position="left"
            size="medium"
        >
          <!-- <el-form-item label="论文作者:" size="small">
                        <el-input v-model="handleBo.papers == null ? '' : handleBo.papers.author" :disabled="true">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="论文标题:" size="small">
                        <el-input v-model="handleBo.papers == null ? '' : handleBo.papers.title" :disabled="true">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="检测卡密:" size="small" >
                        <el-input v-model="handleBo.handle == null ? '' : handleBo.handle.carMy" :disabled="true">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="使用时间:" size="small">
                        <el-input v-model="handleBo.handle == null ? '' : handleBo.handle.createDate" :disabled="true">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="检测状态:" size="small">
                        <el-input
                            v-model="handleBo.handle === null ? '' : handleBo.handle.status == 1 ? '已完成' : '检测中'"
                            :disabled="true"></el-input>
                    </el-form-item> -->
          <el-form-item>
            <el-button
                type="success"
                @click="downloadBao"
                round
                :disabled="
                handleBo.handle == null
                  ? false
                  : handleBo.handle.status == 1
                  ? false
                  : true
              "
            >
              下载报告
            </el-button
            >
            <el-button
                type="primary"
                @click="inquire"
                round

            >
              刷新进度
            </el-button
            >
            <el-button
                round
                type="danger"
                @click="deleteBao"
                :disabled="
                handleBo.handle == null
                  ? false
                  : handleBo.handle.status == 1
                  ? false
                  : true
              "
            >
              删除记录
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <h4 class="notice-title">
        <i class="el-icon-help"></i>
        &nbsp; 下载须知
      </h4>
      <ul class="rightFooter">
        <li>
            <span class="label label-danger">查重时间</span
            >正常1-30分钟出结果，高峰期1-5小时（夜间凌晨24:00后提交的，一般是上午10点左右出结果）\\\\可以隔几分钟点一下刷新进度按钮，刷新检测状态。
        </li>
        <li>
            <span class="label label-danger">查重说明</span
            >查重不会留下痕迹，不影响学校检测，不占用学校次数，可放心检测。
        </li>
        <li>
            <span class="label label-warning">定稿系统</span
            >是否作为定稿系统由各自的学校规定，如果学校采用本系统作为查重系统，那么此系统便可作为定稿系统。
        </li>
        <li>
            <span class="label label-danger">检测部分</span
            >目录/摘要/正文/参考文献等，一般从摘要到参考文献就行，其他内容默认不检测。
        </li>
        <li>
            <span class="label label-info">删除记录</span
            >检测完成后，可以自行删除检测报告和论文，删除后不可恢复，请删除前确认。
        </li>
        <li>
            <span class="label label-success">打开方式</span
            >下载的报告文件为zip压缩包，需要解压缩后查阅，系统报告若为网页格式，打开方式为浏览器；若为PDF格式，打开方式为PDF阅读器。
        </li>
        <li>
            <span class="label label-info">保存时间</span
            >检测报告保留7天，检测完成后请尽快下载报告到自己电脑上永久保存。
        </li>
      </ul>
    </div>
    <div class="left-box">
      <!-- <div class="download">
        <a href="https://www.vpcheck.cn/#/report">
          <span>点击下载检测结果</span>
        </a>
      </div> -->
      <h3 class="fw">
        <img src="@/assets/fanwei.png" alt="">
        &nbsp; 检测范围
      </h3>
      <el-divider></el-divider>
      <div class="check-area">
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">中文科技期刊论文全文数据库</span>

        <span class="left-box-a"><img src="@/assets/slec.png" alt="">中文主要报纸全文数据库</span>

        <span class="left-box-a"><img src="@/assets/slec.png" alt="">中国专利特色数据库</span>

        <span class="left-box-a"><img src="@/assets/slec.png" alt="">博士/硕士学位论文全文数据库</span>

        <span class="left-box-a"><img src="@/assets/slec.png" alt="">中国主要会议论文特色数据库</span>

        <span class="left-box-a"><img src="@/assets/slec.png" alt="">港澳台文献资源</span>

        <span class="left-box-a"><img src="@/assets/slec.png" alt="">外文特色文献数据全库</span>
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">外文特色文献数据全库</span>
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">维普优先出版论文全文数据库</span>
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">互联网数据资源/互联网文档资源</span>
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">高校自建资源库</span>
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">图书资源</span>
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">古籍文献资源</span>
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">年鉴资源</span>
        <span class="left-box-a"><img src="@/assets/slec.png" alt="">IPUB原创作品</span>
      </div>

      <!-- <h3 class="fw" style="margin-top: 40px;position: relative;">
        <img src="@/assets/exc.png" alt="" style="background-color: #0c368a;border-radius: 50%;" >
        &nbsp; 论文客服咨询
        <div class="ercode" v-show="ercode">
          <div style="display: flex;justify-content: space-around;">
            <img src="@/assets/erer.png" width="300" alt="">
            <span style="line-height: 50px;cursor: pointer;" @click="ercode = false">X</span>
          </div>
          <div style="text-align: center;margin-top: 20px;">
            <img src="@/assets/ercode.png" width="288" alt="">
          </div>
        </div>
      </h3> -->
      <el-divider></el-divider>
      <div class="excuse" @mouseenter="ercodeHandle"><img src="@/assets/wechat.png" style="margin-right: 16px;" width="24" alt="">论文客服</div>
      <h3 class="fw">
        &nbsp; 
      </h3>
      <h3 class="fw" style="margin-top: -20px;">
        <img src="@/assets/help.png" alt="">
        &nbsp; 温馨提示
      </h3>
      <el-divider></el-divider>
      <div class="disInfo">
        <p class="title">1.如何统计论文字符数</p>
        <p class="content">打开文档，点击左上角的 审阅 功能 然后点击左上角 字数统计，查看 字符数（不计空格）。不满1千字符算1千字符，例如5001-5999 都算6千字符；12001-12999 都算13千字符。</p>
      </div>
      <div class="disInfo">
        <p class="title">2.检测报告</p>
        <p class="content">PDF报告、整体报告、比对报告---多版本报告以及总相似比、引用率、重复率、自写率---多指标提示</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "report",
  data() {
    return {
      carMy: "",
      handleBo: {handle: null, handleFile: null, papers: null},
      ercode: false
    };
  },
  methods: {
    ercodeHandle(){
      this.ercode = true
    },
    inquire() {
      if (this.carMy == "") {
        this.$message({message: "请输入检测卡号再点击查询按钮", type: "warning"});
        return;
      }
      this.axios.get("/handlefile/find/" + this.carMy).then((res) => {
        this.handleBo = res.data;
        if (this.handleBo.handle == null && this.handleBo.handleFile == null && this.handleBo.papers == null) {
          this.$message({message: "检测卡号未使用或者错误，请检查卡号", type: "error"});
          return;
        }
        if (this.handleBo.handleFile.status == 1 || this.handleBo.handle == null) {
          this.$message({message: "报告被删除", type: "warning"});
          this.handleBo = {handle: null, handleFile: null, papers: null};
          return;
        }
        if (this.handleBo.handle.status == 0 || this.handleBo.handleFile.status == 0) {
          this.$message({message: "检测完成，若不能下载报告请联系客服处理", type: "success"});
          return;
        }
      });
    },
    downloadBao() {
      if (this.handleBo.handleFile == null) {
        return;
      }
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = this.handleBo.handleFile.handleFileName;
      document.body.appendChild(link);
      link.click();
      // var x = new XMLHttpRequest();
      // var jubu=this.handleBo;
      // x.open("GET", this.handleBo.handleFile.handleFileName, true);
      // x.responseType = 'blob';
      // x.onload=function(e) {
      //   //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
      //   var url = window.URL.createObjectURL(x.response)
      //   var a = document.createElement('a');
      //   a.href = url;
      //   a.download =jubu.papers.author+'-'+jubu.papers.title;
      //  // a.download =jubu.papers.title+'_'+jubu.papers.author;
      //   a.click()
      // }
      // x.send();
    },
    deleteBao() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            if (this.handleBo.handleFile == null) {
              return;
            }
            let aaa = this.handleBo.handleFile.handleFileName;
            let ccc = aaa.substring(
                aaa.indexOf("2022-check-mulu") + "2022-check-mulu".length + 1,
                aaa.indexOf("?")
            );
            this.axios.get("/file/remove/" + ccc).then((res) => {
              if (res.data == "ok") {
                this.axios
                    .get("/handlefile/update/" + this.handleBo.handleFile.carMy)
                    .then((res) => {
                      if (res.data == "ok") {
                        this.$message({
                          type: "success",
                          message: "删除成功!",
                        });
                        this.handleBo = {
                          handle: null,
                          handleFile: null,
                          papers: null,
                        };
                      } else {
                        this.$message({
                          type: "error",
                          message: "删除失败!",
                        });
                      }
                    });
              } else {
                this.$message({
                  type: "error",
                  message: "删除失败!",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
  },
  mounted() {
    var carMy = this.$route.query;
    if (carMy.query.length>0){
      this.carMy= carMy.query;
      this.inquire();
    }
  },
};
</script>

<style scoped>
#report {
  display: flex;
}

.left-box {
  flex: 1;
  text-align: left;
  min-width: 240px;
}

.right-box {
  flex: 3;
  padding: 0 10%;
}

.rightFooter {
  width: 100%;
  padding: 28px 0px 35px 0px;
}

.rightFooter li {
  width: 96%;
  position: relative;
  color: #878787;
  padding-left: 100px;
  line-height: 22px;
  font-size: 15px;
  margin-bottom: 10px;
}

.rightFooter span {
  display: inline-block;
  height: 22px;
  text-align: center;
  line-height: 22px;
  color: #fff;
  font-size: 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0px 13px;
  position: absolute;
  left: 0;
}

.box-hr {
  border-top: 1px solid #1b7a02;
  border-bottom: 2px solid #1b7a02;
  height: 5px;
  margin: 10px 0;
  width: 100%;
}

.left-box-a {
  display: block;
  line-height: 26px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}

.left-box-a i {
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin-right: 5px;
  margin-top: -2px;
}

.rightFooter {
  width: 100%;
  padding: 28px 0px 35px 0px;
  list-style: none;
  text-align: left;
}

.notice-title {
  padding-left: 20px;
  font-weight: bold;
  line-height: 40px;
  border-bottom: 2px solid #f3f3f3;
  margin-top: 0;
  text-align: left;
}

.up_box /deep/ .el-form-item__content {
  display: flex;
  justify-content: center;
}

.rightFooter li {
  width: 96%;
  position: relative;
  color: #878787;
  padding-left: 100px;
  line-height: 22px;
  font-size: 15px;
  margin-bottom: 10px;
}

.rightFooter span {
  display: inline-block;
  height: 22px;
  text-align: center;
  line-height: 22px;
  color: #fff;
  font-size: 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0px 13px;
  position: absolute;
  left: 0;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}

.ercode {
  padding: 4px 14px 50px;
  position: absolute;
  width: 330px;
  left: -400px;
  top: -60px;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
}


.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}

.download {
  background: #0142a8;
  height: 40px;
  position: relative;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.download span {
  line-height: 40px;
  color: #ffffff;
}

.download a {
  text-decoration: none;
}

.table {
  table-layout:fixed;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.table thead > tr > th {
  border-bottom: 0px;
  background-color: #f6f6f6;
  padding: 0px;
  line-height: 40px;
  font-weight: bold;
  border-bottom: 2px solid green;
  font-size: 14px;
  border-left: 1px dashed #ccc;
}

.table thead > tr > th:last-child{
  border-right: 1px dashed #ccc;
}

.no-res {
  font-size: 18px;
  color: #424242;
  text-align: center;
  padding: 51px 0;
}

.fw {
  font-size: 20px;
  font-weight: normal;
}

.fw img {
  vertical-align: -4px;
}

.excuse {
  width: 226px;
  text-align: center;
  color: #6f6f6f;
  background: #f5f5f5;
  border: 1px solid #d6d6d6;
  font-size: 15px;
  line-height: 40px;
  margin-top: 20px;
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.excuse .img {
  width: 24px;
  height: 24px;
}

.disInfo {
  margin-top: -10px;
  font-size: 14px;
}

.disInfo .title {
  line-height: 1.6;
  margin-bottom: 0;
  color: #0c368a;
  padding-top: 22px;
}

.disInfo .content {
  color: #757575;
  font-size: 12px;
  font-family: SimSun;
  line-height: 24px;
  margin: 0;
}

.ttitle {
  line-height: 40px;
  font-size: 20px;
  font-weight: 700;
  margin-right: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

.el-icon-search {
  padding-right: 5px;
}

.check-area {
  height: 212px;
  overflow-y: scroll;
}

.topTils {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  border-bottom: 1px dashed #cdcdcd;
  margin-bottom: 50px;
}

.table td{
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>